import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import styled from "styled-components"


const Centered = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  img {
    width: 70%;
    margin-top: -5rem;
  }
`

const IndexPage = () => (
  <Layout>
    <Seo title="Dylan Yuen" />

    <Centered><img src="/images/lgo-dylan.svg" alt="Dylan Yuen" /></Centered>

  </Layout>
)

export default IndexPage
